
















import { getGuid } from '@homestyle/shared-data';
import { register } from '@homestyle/rich-editor';
import { stringify } from 'himalaya';
import ClientOnly from 'vue-client-only';
import { useModule } from 'vuex-simple';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import EditorRender from './components/editor-render';
import { EventBusService } from '../../services/event-bus.service';
import { AppEvents } from '../../utils/app-event.enum';
import { GalleryStore } from '../../../Gallery';
import { PageStore } from '../../../Page';

@Component({
	components: { ClientOnly, EditorRender },
})
export class Editor extends Vue {
	@Prop({ default: false }) isGallery: boolean;

	public get assets(): string[] {
		return this.pageStore.getAssets;
	}
	public editorID = `editor-${getGuid()}`;

	public get content(): string {
		const htmlContent = this.isGallery
			? this.galleryStore.getGalleryContent
			: this.pageStore.getPageContent;

		return stringify(htmlContent);
	}

	public get forceChangeKey(): number {
		return this.forceCounter;
	}

	private forceCounter = 0;
	private galleryStore?: GalleryStore = useModule(this.$store, ['gallery']);
	private pageStore?: PageStore = useModule(this.$store, ['page']);

	public created(): void {
		register();

		EventBusService.on(AppEvents.assets, this.syncEvent);

		this.pageStore.dispatchAssetsImageUrls();
	}

	@Watch('content')
	private forceUpdate() {
		this.forceCounter += 1;
	}

	private syncEvent() {
		this.pageStore.dispatchAssetsImages();
	}
}

export default Editor;
