





























import type { SocialBtns } from '@homestyle/shared-data';
import { Component, Vue } from 'vue-property-decorator';

import { socialMedia } from '../../utils/social.helper';

@Component({})
export class SocialComponent extends Vue {
	public socialBtns: SocialBtns[] = socialMedia;

	public openMediaTab(link: string) {
		open(link);
	}
}

export default SocialComponent;
