import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  Method,
  ResponseType,
} from 'axios';
import { from } from 'rxjs';
import type { AjaxConfig } from 'rxjs/ajax';

export class HttpClientService {
  private axiosInstance: AxiosInstance;
  private axiosDefaults: AxiosRequestConfig;

  constructor() {
    const config: AxiosRequestConfig = {
      baseURL: process.env.REST_HOST || '',
    };

    this.axiosInstance = axios.create(config);

    this.axiosDefaults = this.axiosInstance.defaults;
  }

  public request(options: AjaxConfig) {
    const requestData =
      options.method.toLowerCase() === 'post' ? { data: options.body } : {};
    const config: AxiosRequestConfig = {
      headers: options.headers,
      method: options.method as Method,
      responseType:
        (options.responseType as ResponseType) ||
        this.axiosDefaults.responseType,
      url: options.url,
      ...requestData,
    };

    return from(this.axiosInstance.request(config));
  }
}

export const ajax = new HttpClientService();
export default ajax;
