import { SmartBlock, Extensions } from 'smartblock';

import './EditorRoot.scss';
import { SaveButton } from '../../components/SaveButton';
import { FontSize } from '../../extensions/font-size';
import { Image } from '../../extensions/image';
import { filterContent, storeService, useStore } from '../../store';

const setSave = (output: { html: string }) => {
  const content = filterContent(output.html);

  storeService.setContent(content);
};

export const EditorRoot = () => {
  const { html } = useStore();

  return (
    <>
      <SaveButton />
      <SmartBlock
        html={html}
        extensions={[...Extensions, new Image(), new FontSize()]}
        onChange={setSave}
      />
    </>
  );
};

export default EditorRoot;
