import * as React from 'react';
import { Subject } from 'rxjs';

import { FontSizeIcon } from '../../components/FontSizeIcon';
import { FontSizeInput } from '../../components/FontSizeInput';
import { buttonId, useOutsideCancel } from '../../hooks/outside-cancel.hook';
import { useStore } from '../../store';
import './FontSizeButton.scss';

export const markEvent = new Subject<void>();

export const FontSizeButton = () => {
  const ref = useOutsideCancel();
  const { showFontMark } = useStore();

  return (
    <div id={buttonId} className="FontSizeButton">
      <FontSizeIcon />
      {showFontMark && (
        <div ref={ref} className="FontSizePicker" onClick={evt => evt.stopPropagation()}>
          <FontSizeInput />
          <div className="FontSizeDone" onClick={() => markEvent.next()}>
            <span className="material-icons">done</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FontSizeButton;
