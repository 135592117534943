export const FontSizeIcon = () => (
  <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M2 4V7H7V19H10V7H15V4H2M21 9H12V12H15V19H18V12H21V9Z"
    />
  </svg>
);

export default FontSizeIcon;
