











import { Component, Vue } from 'vue-property-decorator';

import { EventBusService } from '../../services/event-bus.service';
import type { EventBus } from '../../services/event-bus.service';
import { AuthMessages } from '../../utils/auth-event.enum';
import { AppEvents, AppEventLimit, AppMessages } from '../../utils/app-event.enum';
import { Once } from '../../utils/once.decorator';

@Component({})
export class Toast extends Vue {
	public get color() {
		return this.colorSnack;
	}
	public get show() {
		return this.snackShow;
	}

	public get message() {
		return this.snackMessage;
	}

	public mounted() {
		EventBusService.on(AppEvents.complete, (completeEvt) => this.completeEvent(completeEvt));
	}

	public userLogin(useGoogle) {
		this.$store.dispatch('loginUser', useGoogle);
	}

	private colorSnack = 'success';
	private snackMessage = '';
	private snackShow = false;

	@Once(AppEventLimit.complete)
	private completeEvent(evt: EventBus) {
		const msg = evt.payload as string;
		this.resetSnack(msg);
	}

	private resetSnack(message: string) {
		const messages = [
			AuthMessages.access,
			AuthMessages.failedCreate,
			AuthMessages.failedLogin,
			AppMessages.messageError,
			AppMessages.photoError,
			AppMessages.saveError,
		] as string[];
		const isError = messages.includes(message);
		this.colorSnack = isError ? 'error' : 'success';
		this.snackMessage = message;
		this.snackShow = true;

		setTimeout(() => {
			this.snackShow = false;
			this.snackMessage = '';
			this.colorSnack = 'success';
		}, 6000);
	}
}

export default Toast;
