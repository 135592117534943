import { EditorEvents } from '@homestyle/shared-data';
import { parse } from 'himalaya';
import { useRef } from 'react';

import { useStore } from '../../store';
import './SaveButton.scss';
export const SaveButton = () => {
  const btnRef = useRef<HTMLDivElement>(null);
  const { html } = useStore();

  const save = () => {
    const saveEvent = new CustomEvent(EditorEvents.save, {
      detail: parse(html),
      bubbles: true,
    });

    if (btnRef.current) {
      btnRef.current.dispatchEvent(saveEvent);
    }
  };

  return (
    <div ref={btnRef} className="SaveButton" onClick={() => save()}>
      <span className="material-icons">save</span>
    </div>
  );
};

export default SaveButton;
