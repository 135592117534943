import type { UserState } from '@homestyle/shared-data';
import { auth } from 'firebase-admin';

export const chunk = (input: unknown[], size: number) =>
  Array.from({ length: Math.ceil(input.length / size) }, (_v, i) => input.slice(i * size, i * size + size));

export const chunkUsers = (current: number, users: auth.UserRecord[]) => {
  const newState: UserState = {};
  const groups = chunk(users, 10);
  groups.forEach((group, index) => {
    newState[current + index] = group as auth.UserRecord[];
  });

  return newState;
};

export default chunk;
