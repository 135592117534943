export type HTMLObject = TextElement | HTMLElement;

export enum ElementType {
  element = 'element',
  text = 'text',
}

interface Attributes {
  key: string;
  value: string;
}

interface TextElement {
  content: string;
  type: ElementType.text;
}

interface HTMLElement {
  attributes: Attributes[];
  children: HTMLObject[];
  tagName: string;
  type: ElementType.element;
}

export default HTMLObject;
