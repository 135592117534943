import { firebaseConfig as devConfig } from './firebase-dev.config';
import { firebaseConfig as prodConfig } from './firebase-prod.config';

const { location } = window;

const isProd = /(homestyle)/.test(location.hostname);
const isStage = /(dev|localhost)/.test(location.hostname);

export const environment = {
  firebaseConfig: isStage ? devConfig : prodConfig,
  production: isProd,
};
