export const Once = (trigger: boolean) => {
  return (
    _target: Record<string, any>,
    _key: string | symbol,
    descriptor: PropertyDescriptor
  ) => {
    const original = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      if (trigger) {
        original.apply(this, args);
      }
      trigger = !trigger;
    };

    return descriptor;
  };
};

export default Once;
