import type {
  AuthFormModal,
  AuthFormErrors,
  CurrentUser,
  CurrentUserModel,
  EmailModal,
  LoginTypeModal,
} from '@homestyle/shared-data';
import { UserRoles } from '@homestyle/shared-data';
import { Action, Getter, Mutation, State } from 'vuex-simple';
import { of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, take } from 'rxjs/operators';

import { authService } from '../services/auth.service';
import { EventBusService } from '../services/event-bus.service';
import { AuthMessages } from '../utils/auth-event.enum';
import { AppEvents } from '../utils/app-event.enum';
import { logger } from '../utils/logger';

const rootStateInit = {
  currentUserState: {
    role: UserRoles.Anonymous,
  },
  errors: {
    newUser: {
      error: false,
      message: '',
    },
    userName: {
      error: false,
      message: '',
    },
  },
  form: {
    password: '',
    userName: '',
  },
};

export class RootStore {
  @State()
  protected authFormState: Readonly<AuthFormModal> = rootStateInit.form;

  @State()
  protected authFormErrorState: Readonly<AuthFormErrors> = rootStateInit.errors;

  @State()
  protected currentUserState: Readonly<CurrentUserModel> = {
    role: UserRoles.Anonymous,
  };

  @Getter()
  public get currentUser() {
    return this.currentUserState;
  }

  @Getter()
  public get formErrors() {
    return this.authFormErrorState;
  }

  @Mutation()
  public commitAuthErrors(payload: Partial<AuthFormErrors>) {
    logger.storeLog.info('setting auth errors');
    this.authFormErrorState = { ...this.authFormErrorState, ...payload };
  }

  @Mutation()
  public commitAuthForm(payload: AuthFormModal) {
    logger.storeLog.info('setting auth form');
    this.authFormState = { ...this.authFormState, ...payload };
  }

  @Mutation()
  public commitAuthReset() {
    logger.storeLog.info('Resetting Auth state');
    this.authFormState = { ...rootStateInit.form };
    this.authFormErrorState = { ...rootStateInit.errors };
  }

  @Mutation()
  public commitCurrentUser(payload: CurrentUser) {
    this.currentUserState = { ...this.currentUserState, ...payload };
  }

  @Action()
  public dispatchAuthLogin(payload: LoginTypeModal) {
    logger.storeLog.info(
      `Login with ${payload.useGoogle ? 'Google' : 'email'}`
    );
    authService
      .signIn(payload.useGoogle, this.authFormState)
      .subscribe((data) => {
        this.commitCurrentUser(data);
        this.commitAuthReset();
        EventBusService.emit({
          event: AppEvents.complete,
          payload: AuthMessages.successLogin,
        });
      });
  }

  @Action()
  public dispatchAuthLogout() {
    logger.storeLog.info('Logging out');
    const emitter = [1, 2];
    authService.signOut().subscribe({
      next: () => {
        this.commitCurrentUser({ ...rootStateInit.currentUserState });
        emitter.forEach(() => {
          EventBusService.emit({
            event: AppEvents.complete,
            payload: AuthMessages.userLogout,
          });
        });
      },
    });
  }

  @Action()
  public dispatchCreateUser() {
    logger.storeLog.info(`Create a new user ${this.authFormState.userName}`);
    authService.createUser(this.authFormState).subscribe((user) => {
      logger.storeLog.info('User Role: ', user.customClaims);
      EventBusService.emit({
        event: AppEvents.complete,
        payload: AuthMessages.created,
      });
    });
  }

  @Action()
  public dispatchEmailCheck(payload: EmailModal) {
    logger.storeLog.info(`Validating ${payload.email} as a user`);
    const isEmail = /.+@.+\..+/.test(payload.email);
    if (isEmail) {
      authService
        .checkEmail(payload.email)
        .pipe(
          take(1),
          catchError((err: AjaxError) => this.handleEmailError(err))
        )
        .subscribe((data: AjaxResponse<{ isValid: boolean }>) => {
          if (data && data.response) {
            this.commitAuthErrors({
              newUser: {
                error: data.response.isValid,
                message: 'Please try another Email.',
              },
              userName: {
                error: !data.response.isValid,
                message: '',
              },
            });
          }
        });
    } else {
      this.commitAuthErrors({
        newUser: {
          error: false,
          message: '',
        },
        userName: {
          error: false,
          message: '',
        },
      });
    }
  }

  private handleEmailError(err: AjaxError) {
    logger.storeLog.error(err.message, err);
    if (err.response) {
      this.commitAuthErrors({
        newUser: {
          error: err.response.isValid,
          message: '',
        },
        userName: {
          error: !err.response.isValid,
          message: 'Username does not Exist.',
        },
      });
    }

    return of(err.response);
  }
}

export default RootStore;
