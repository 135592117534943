




import { UserRoles } from '@homestyle/shared-data';
import { useStore } from 'vuex-simple';

import { MyStore } from '../../../store';
import AuthButton from '../../components/AuthButton';
import { EventBusService } from '../../services/event-bus.service';
import { AuthEvents } from '../../utils/auth-event.enum';

export default {
	components: {
		AuthButton,
	},
	data() {
		const store: MyStore = useStore(this.$store);

		return { store };
	},
	created() {
		EventBusService.on(AuthEvents.action, () => this.handleUser());
	},
	computed: {
		loggedIn() {
			const store: MyStore = this.store;

			return store.currentUser.role !== UserRoles.Anonymous;
		},
	},
	methods: {
		getRedirectPath() {
			const { location } = window;
			const isLoginPage = location.pathname === '/login';
			const query = new URLSearchParams(location.search);
			let path = isLoginPage ? '/' : location.pathname;

			if (query.has('redirect') && query.get('redirect')) {
				path = query.get('redirect');
			}

			return { isLoginPage, path };
		},
		handleUser() {
			const { isLoginPage, path } = this.getRedirectPath();

			if (this.loggedIn) {
				this.store.dispatchAuthLogout();
			}

			if (!isLoginPage) {
				this.$router.push(`/login?redirect=${path}`);
			}
		},
		newUser() {
			const store: MyStore = this.store;

			store.dispatchCreateUser();
		},
	},
	watch: {
		loggedIn() {
			const { isLoginPage, path } = this.getRedirectPath();

			if (this.loggedIn && isLoginPage) {
				this.$router.push(path);
			}
		},
	},
};
