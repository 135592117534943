import { ExtensionSchema as BasicSchema } from 'smartblock/lib/types';

interface Attribute {
  default: unknown;
  [key: string]: unknown;
}

interface ParseDom {
  getAttrs?: (dom?: Element) => Record<string, unknown>;
  style?: string;
  tag?: string;
}

export interface ExtensionSchema extends BasicSchema {
  attrs?: Record<string, Attribute>;
  defaultAttrs?: Record<string, unknown>;
  parseDOM: ParseDom[];
}

export enum ImageClasses {
  contentClass = 'hsImgContent',
  figureClass = 'hsFigure',
  imgClass = 'hsImg',
  imgCenterClass = 'hsImgCenter',
  imgLeftClass = 'hsImgLeft',
  imgRightClass = 'hsImgRight',
  wrapperClass = 'hsImageWrapper',
}

export enum ImageAlignPosition {
  center,
  left,
  right,
}

export const getClass = (isWrapper: boolean, pos: ImageAlignPosition) => {
  let className = '';

  if (isWrapper && pos === ImageAlignPosition.center) {
    className = ImageClasses.imgCenterClass;
  }

  if (!isWrapper && pos === ImageAlignPosition.left) {
    className = ImageClasses.imgLeftClass;
  }

  if (!isWrapper && pos === ImageAlignPosition.right) {
    className = ImageClasses.imgRightClass;
  }

  return className;
};
