/// <reference types="@types/gapi" />
/// <reference types="@types/gapi.auth2" />
import type { Album, MediaItem } from '@homestyle/shared-data';

import { environment } from '../../../environments/environment';

const { firebaseConfig } = environment;

export class PhotosService {
  // eslint-disable-next-line @typescript-eslint/camelcase
  private params = { client_id: firebaseConfig.clientId, scope: firebaseConfig.scopes.join(' ') };

  public get gapi() {
    const { gapi } = window || null;

    return gapi;
  }

  public async getAlbums() {
    const user = await this.getAuth();
    await user.get().reloadAuthResponse();
    const res: gapi.client.HttpRequestFulfilled<{
      sharedAlbums: Album[];
    }> = await this.gapi.client.request({
      path: 'https://photoslibrary.googleapis.com/v1/sharedAlbums',
    });

    return res.result ? res.result.sharedAlbums : [];
  }

  public async getPhotos(id: string) {
    const user = await this.getAuth();
    await user.get().reloadAuthResponse();
    const res = await this.fetchPhotos(id);
    const media = res.result && res.result.mediaItems ? res.result.mediaItems : [];
    if (res.result.nextPageToken) {
      let token = res.result.nextPageToken;

      while (token) {
        const newItems = await this.fetchPhotos(id, token);
        token = newItems.result.nextPageToken;
        media.push(...newItems.result.mediaItems);
      }
    }

    return media;
  }

  public start() {
    this.gapi.load('client', this.init);
  }

  public stop() {
    this.gapi.load('auth2', () => {
      let authClient = this.gapi.auth2.getAuthInstance();

      if (!authClient) {
        authClient = this.gapi.auth2.init(this.params);
      }

      authClient.signOut();
    });
  }

  private async fetchPhotos(id: string, token?: string) {
    const query = token ? { pageToken: token } : {};
    const res: gapi.client.HttpRequestFulfilled<{
      mediaItems: MediaItem[];
      nextPageToken?: string;
    }> = await this.gapi.client.request({
      body: { ...query, albumId: id, pageSize: 100 },
      method: 'post',
      path: 'https://photoslibrary.googleapis.com/v1/mediaItems:search',
    });

    return res;
  }

  private getAuth(): Promise<gapi.auth2.CurrentUser> {
    return new Promise((resolve) => {
      this.gapi.load('auth2', () => {
        let authClient = this.gapi.auth2.getAuthInstance();

        if (!authClient) {
          authClient = this.gapi.auth2.init(this.params);
        }

        resolve(authClient.currentUser);
      });
    });
  }

  private getAuthClient() {
    this.gapi.load('auth2', () => {
      const client = this.gapi.auth2.getAuthInstance();
      const signedIn = client.isSignedIn.get();
      if (!signedIn) {
        client.signIn(this.params);
        console.log(client.isSignedIn.get());
      }
    });
  }

  private init = async () => {
    await this.gapi.client.init({
      clientId: firebaseConfig.clientId,
      discoveryDocs: firebaseConfig.discoveryDocs,
      scope: firebaseConfig.scopes.join(' '),
    });

    this.getAuthClient();
  };
}

export const photosService = new PhotosService();

export default photosService;
