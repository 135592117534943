import { useRef } from 'react';

import { ImgSize, storeService, useStore } from '../../store';
import './ImageItem.scss';

interface ImgSelection {
  naturalSize: ImgSize | null;
  selected: number | null;
}

export interface ImageItemProps {
  selector: number;
  url: string;
}

export const ImageItem = ({ selector, url }: ImageItemProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const { selected } = useStore();

  const getSelection = () => {
    const isSelected = selector === selected;
    const img = imgRef.current;
    let selection: ImgSelection = { naturalSize: null, selected: null };

    if (!isSelected) {
      selection = { ...selection, selected: selector };

      if (img) {
        selection = {
          ...selection,
          naturalSize: { height: img.naturalHeight, width: img.naturalWidth },
        };
      }
    }

    storeService.setSelect(selection.selected, selection.naturalSize);
  };

  return (
    <div className="ImageItem" onClick={() => getSelection()}>
      <img
        ref={imgRef}
        className={`imageItemImg ${selector === selected && 'imageItemSelect'}`}
        alt="Library Asset"
        src={url}
      />
    </div>
  );
};

export default ImageItem;
