

















































































































































import ClientOnly from 'vue-client-only';
import Vue from 'vue';

import FooterComponent from './shared/components/FooterComponent';
import NavbarComponent from './shared/components/NavbarComponent';
import SocialComponent from './shared/components/SocialComponent';
import ProgressBar from './shared/components/ProgressBar';
import AuthComponent from './shared/containers/AuthComponent';
import Toast from './shared/containers/Toast';
import { metaTags } from './shared/utils/meta-tags';

export const App = Vue.extend({
	components: {
		AuthComponent,
		ClientOnly,
		FooterComponent,
		NavbarComponent,
		ProgressBar,
		SocialComponent,
		Toast,
	},
	metaInfo: {
		...metaTags,
		base: { href: '/', target: '' },
		script: [
			{
				src: 'https://apis.google.com/js/api.js',
				vmid: 'google-photo',
			},
		],
		title: '',
		titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} | Homestyle Custom` : 'Homestyle Custom'),
	},
});

export default App;
