import { getGuid } from '@homestyle/shared-data';
import { render } from 'react-dom';

import { EditorRoot } from './containers/EditorRoot';
import { storeService } from './store';

export class Editor extends HTMLElement {
  public static observedAttributes = ['editor-content', 'gallery-lib'];

  public attributeChangedCallback(name: string) {
    if (name === 'editor-content') {
      storeService.setContent(this.editorContent);
    }

    if (name === 'gallery-lib') {
      storeService.setImgLib(this.galleryLib.split(','));
    }
  }

  public get editorContent() {
    return this.getAttribute('editor-content') || '';
  }

  public set editorContent(value: string) {
    if (value) {
      this.setAttribute('editor-content', value);
    } else {
      this.removeAttribute('editor-content');
    }
  }

  public get galleryLib() {
    return this.getAttribute('gallery-lib') || '';
  }

  public set galleryLib(value: string) {
    this.setAttribute('gallery-lib', value);
  }

  public connectedCallback() {
    const mountPoint = document.createElement(
      'div'
    ) as unknown as globalThis.Element;

    this.initSetStore();

    render(<EditorRoot />, mountPoint);
    mountPoint.id = `editor-${getGuid()}`;
    this.appendChild(mountPoint);
  }

  private initSetStore() {
    const html = this.editorContent;
    const imgLib = this.galleryLib ? this.galleryLib.split(',') : [];

    storeService.setInit({ html, imgLib });
  }
}

export const register = () => {
  const isDefined = customElements.get('homestyle-editor');

  if (!isDefined) {
    customElements.define('homestyle-editor', Editor);
  }
};

export default Editor;
