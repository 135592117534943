import { UserRoles } from '@homestyle/shared-data';

import { RouteConfig } from '../shared/models/meta-tag.model';

export const adminRoutes: RouteConfig[] = [
  {
    component: () => import('./Admin.vue'),
    meta: {
      audience: [UserRoles.Admin],
      title: 'Homestyle Custom | Admin',
    },
    name: 'admin',
    path: '/admin',
  },
];

export default adminRoutes;
