













import Vue from 'vue';

export const PreviewButton = Vue.extend({
	data: () => {
		return {};
	},
	methods: {
		goToPage() {
			const { location, open } = window;
			const { pathname } = location;

			open(`https://homestylecustom.com${pathname}`, '_blank');
		},
	},
});

export default PreviewButton;
