import { getGuid } from '@homestyle/shared-data';
import { setBlockType } from 'prosemirror-commands';
import { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { render } from 'react-dom';
import {
  blockActive,
  Button,
  Extension,
  findSelectedNodeWithType,
} from 'smartblock';
import { Dispatch, ExtensionProps } from 'smartblock/lib/types';

import { ImageAlign } from '../../components/ImageAlign';
import { ImageIcon } from '../../components/ImageIcon';
import { ImageModal } from '../../containers/ImageModal';
import { ResizeButton } from '../../components/ResizeButton';
import { storeService } from '../../store';
import {
  ExtensionSchema,
  getClass,
  ImageAlignPosition,
  ImageClasses,
} from '../../utils';
import { resizeSubject } from '../../utils/resize.util';

export class Image extends Extension {
  private modalId = `image-modal-${getGuid()}`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props?: ExtensionProps) {
    super(props);
  }

  public get group() {
    return 'block';
  }

  public get icon() {
    return <ImageIcon />;
  }

  public get name() {
    return 'image';
  }

  public get schema(): ExtensionSchema {
    return {
      attrs: {
        dataModal: { default: '' },
        height: { default: '' },
        layout: { default: ImageAlignPosition.center },
        src: { default: '' },
        width: { default: '' },
      },
      content: 'inline*',
      group: 'block',
      parseDOM: [
        {
          tag: 'homestyle-image',
          getAttrs: (dom) => {
            const fig = dom.querySelector('figure');

            if (!fig) {
              return {};
            }

            const dataModal = fig.getAttribute('data-modal');
            const layout = Number(fig.getAttribute('data-layout'));
            const img = fig.querySelector('img');
            this.modalId = dataModal;

            if (!img) {
              return { dataModal, layout };
            }

            const height = img.height;
            const src = img.src;
            const width = img.width;

            return {
              dataModal,
              height,
              layout,
              src,
              width,
            };
          },
        },
        {
          tag: 'div',
        },
        {
          tag: 'figure',
          getAttrs: (dom) => {
            const dataModal = dom.getAttribute('data-modal');
            const layout = Number(dom.getAttribute('data-layout'));
            const img = dom.querySelector('img');
            this.modalId = dataModal;

            if (!img) {
              return { dataModal, layout };
            }

            const height = img.height;
            const src = img.src;
            const width = img.width;

            return {
              dataModal,
              height,
              layout,
              src,
              width,
            };
          },
        },
        {
          tag: 'img',
          getAttrs: (dom) => {
            const height = Number(dom.getAttribute('height'));
            const src = dom.getAttribute('src') || '';
            const width = Number(dom.getAttribute('width'));

            return {
              height,
              src,
              width,
            };
          },
        },
      ],
      toDOM: (node: Node) => {
        const textContent =
          node.attrs.layout === ImageAlignPosition.center
            ? []
            : [
                [
                  'div',
                  {
                    class: ImageClasses.contentClass,
                    contenteditable: true,
                  },
                  0,
                ],
              ];

        return [
          'homestyle-image',
          {
            style: `min-height: ${node.attrs.height + 50}px;`,
          },
          [
            'div',
            {
              class: `${ImageClasses.wrapperClass} ${getClass(
                true,
                node.attrs.layout
              )}`,
            },
            [
              'figure',
              {
                class: `${ImageClasses.figureClass} ${getClass(
                  false,
                  node.attrs.layout
                )}`,
                'data-layout': node.attrs.layout,
                'data-modal': node.attrs.dataModal,
                style: `max-height: ${node.attrs.height}px; max-width: ${node.attrs.width}px`,
              },
              [
                'img',
                {
                  class: ImageClasses.imgClass,
                  src: node.attrs.src,
                },
              ],
            ],
            ...textContent,
          ],
        ];
      },
    };
  }

  public get showMenu() {
    return true;
  }

  public active(state: EditorState) {
    return blockActive(state.schema.nodes.image)(state);
  }

  public customMenu({
    state,
    dispatch,
  }: {
    state: EditorState;
    dispatch: Dispatch;
  }) {
    const attrs = this.getAttrs(state);
    const fig = document.querySelector(`[data-modal="${attrs.dataModal}"]`);

    return (
      <>
        <ResizeButton
          onResize={(isResize) =>
            this.triggerResize(fig, isResize, dispatch, state)
          }
        />
        <Button
          active={attrs.layout === ImageAlignPosition.left}
          onClick={() =>
            this.setLayout(ImageAlignPosition.left, dispatch, state)
          }
        >
          <ImageAlign direction={ImageAlignPosition.left} />
        </Button>
        <Button
          active={attrs.layout === ImageAlignPosition.center}
          onClick={() =>
            this.setLayout(ImageAlignPosition.center, dispatch, state)
          }
        >
          <ImageAlign direction={ImageAlignPosition.center} />
        </Button>
        <Button
          active={attrs.layout === ImageAlignPosition.right}
          onClick={() =>
            this.setLayout(ImageAlignPosition.right, dispatch, state)
          }
        >
          <ImageAlign direction={ImageAlignPosition.right} />
        </Button>
      </>
    );
  }

  public enable(state: EditorState) {
    return setBlockType(state.schema.nodes.image)(state);
  }

  public onClick(state: EditorState, dispatch: Dispatch) {
    const wrapperDiv = this.createWrapper();

    render(
      <ImageModal
        modalWrapper={wrapperDiv}
        onInsert={() => this.updateImage(state, dispatch)}
      />,
      wrapperDiv
    );
  }

  private createWrapper() {
    const modal = document.getElementById(this.modalId);
    const div = document.createElement('div');
    div.id = this.modalId;
    div.className = 'imageModal-wrapper';
    if (modal && modal.hasChildNodes()) {
      document.body.removeChild(modal);
    } else {
      document.body.appendChild(div);
    }

    return div;
  }

  private getAttrs(state: EditorState) {
    const node: Node = findSelectedNodeWithType(
      state.schema.nodes.image,
      state
    );
    const { attrs } = node;

    return attrs;
  }

  private setLayout(
    layout: ImageAlignPosition,
    dispatch: Dispatch,
    state: EditorState
  ) {
    const attrs = this.getAttrs(state);

    setBlockType(state.schema.nodes.image, {
      ...attrs,
      layout,
    })(state, dispatch);
  }

  private setResize(dispatch: Dispatch, state: EditorState) {
    const attrs = this.getAttrs(state);

    setBlockType(state.schema.nodes.image, {
      ...attrs,
      ...resizeSubject.value,
    })(state, dispatch);
  }

  private triggerResize(
    fig: HTMLElement | Element,
    isResize: boolean,
    dispatch: Dispatch,
    state: EditorState
  ) {
    if (isResize) {
      const wrapperDiv = this.createWrapper();
      const clonedImg = fig.querySelector('img').cloneNode();

      render(
        <ImageModal
          fig={clonedImg}
          modalWrapper={wrapperDiv}
          onInsert={() => this.setResize(dispatch, state)}
        />,
        wrapperDiv
      );
    }
  }

  private updateImage(state: EditorState, dispatch: Dispatch) {
    const { naturalSize, url } = storeService.getState();

    setBlockType(state.schema.nodes.image, {
      dataModal: this.modalId,
      height: naturalSize.height,
      src: url,
      width: naturalSize.width,
    })(state, dispatch);

    storeService.setSelect(null, null);
  }
}
