import { useRef } from 'react';

import { storeService, useStore } from '../../store';
import './FontSizeInput.scss';

export const FontSizeInput = () => {
  const numRef = useRef<HTMLInputElement>(null);
  const { fontSize } = useStore();

  const setValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    storeService.setFontSize(Number(value));
  };

  const changeSize = (isUp: boolean) => {
    const changeEvent = new Event('change', { bubbles: true });

    if (numRef.current) {
      if (isUp) {
        numRef.current.stepUp();
      } else {
        numRef.current.stepDown();
      }

      numRef.current.dispatchEvent(changeEvent);
    }
  };

  return (
    <>
      <input
        className="FontSizeInput"
        ref={numRef}
        type="number"
        value={fontSize}
        min={0}
        onChange={setValue}
      />
      <div className="FontSizeControlWrapper">
        <div className="FontSizeControl" onClick={() => changeSize(false)}>
          <span className="material-icons">remove</span>
        </div>
        <div>{fontSize}</div>
        <div className="FontSizeControl" onClick={() => changeSize(true)}>
          <span className="material-icons">add</span>
        </div>
      </div>
    </>
  );
};

export default FontSizeInput;
