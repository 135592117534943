import './registerServiceWorker';

import Vue from 'vue';
import { VueConfiguration } from 'vue/types/vue';
import { VuePlugin } from 'vuera';

import App from './app/App.vue';
import { createRouter } from './app/router';
import store, { myStore } from './app/store';
import { firebaseSetup } from './app/shared/utils/firebase.setup';
import { logger } from './app/shared/utils/logger';
import AuthGuard from './plugins/auth.guard';
import vuetify from './plugins/vuetify';

const isDev = process.env.NODE_ENV === 'development';
const config: Partial<VueConfiguration> = {
  devtools: isDev,
  productionTip: isDev,
  silent: !isDev,
};

Vue.config.devtools = config.devtools;
Vue.config.productionTip = config.productionTip;
Vue.config.silent = config.silent;

Vue.use(VuePlugin);

const setLogger = () => {
  logger.setup(isDev);
};

const createApp = () => {
  firebaseSetup().finally(() => {
    myStore.init();
  });
  setLogger();

  const router = createRouter();
  router.beforeEach(AuthGuard);

  const app = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  });

  return { app, router };
};

// client-specific bootstrapping logic...
const { app, router } = createApp();

if (window['__INITIAL_STATE__']) {
  // We initialize the store state with the data injected from the server
  store.replaceState(window['__INITIAL_STATE__']);
}

router.onReady(() => {
  // this assumes App.vue template root element has `id="app"`
  const appID = '#app';
  app.$mount(appID);
});
