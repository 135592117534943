import { Button } from 'smartblock';

import { storeService, useStore } from '../../store';
import { ResizeIcon } from '../ResizeIcon';

export interface ResizeButtonProps {
  onResize: (isResize: boolean) => void;
}

export const ResizeButton = ({ onResize }: ResizeButtonProps) => {
  const { resizeActive } = useStore();

  const onSelect = () => {
    storeService.setResize(!resizeActive);
    onResize(!resizeActive);
  };

  return (
    <Button active={resizeActive} type="button" onClick={onSelect}>
      <ResizeIcon />
    </Button>
  );
};

export default ResizeButton;
