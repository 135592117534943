import { ImageAlignPosition } from '../../utils';

export interface ImageAlignProps {
  direction: ImageAlignPosition;
}

export const ImageAlign = ({ direction }: ImageAlignProps) => (
  <>
    {direction === ImageAlignPosition.center && (
      <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M19,16V13H23V11H19V8L15,12L19,16M5,8V11H1V13H5V16L9,12L5,8M11,20H13V4H11V20Z"
        />
      </svg>
    )}
    {direction === ImageAlignPosition.left && (
      <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11,16V13H21V11H11V8L7,12L11,16M3,20H5V4H3V20Z"
        />
      </svg>
    )}
    {direction === ImageAlignPosition.right && (
      <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M13,8V11H3V13H13V16L17,12L13,8M19,20H21V4H19V20Z"
        />
      </svg>
    )}
  </>
);

export default ImageAlign;
