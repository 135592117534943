import type { UserRole, UserState } from '@homestyle/shared-data';
import { auth } from 'firebase-admin';
import { Action, Getter, Mutation, State } from 'vuex-simple';

import { MyStore } from '../../store';
import { adminService } from '../../shared/services/admin.service';
import { EventBusService } from '../../shared/services/event-bus.service';
import { AdminEvents } from '../../shared/utils/admin-event.enum';
import { chunkUsers } from '../../shared/utils/chuck.helper';
import { logger } from '../../shared/utils/logger';

const adminStateInit = {
  nextPage: '',
  users: {},
};

export class AdminStore {
  @State()
  protected nextPage = '';

  @State()
  protected users: UserState = {};

  @Getter()
  public get adminState() {
    const { nextPage, users } = this;

    return { nextPage, users };
  }

  constructor(private root: MyStore) {}

  @Mutation()
  public commitAdminReset() {
    logger.adminStore.info('Resetting Admin State');

    this.nextPage = adminStateInit.nextPage;
    this.users = { ...adminStateInit.users };
  }

  @Mutation()
  public commitUserPages(payload: { nextToken: string; pages: UserState }) {
    const { users } = this;
    logger.adminStore.info('Updating Next Page Token');
    this.nextPage = payload.nextToken;

    logger.adminStore.info('Updating User pages');
    this.users = { ...users, ...payload.pages };
    EventBusService.emit({ event: AdminEvents.users, payload: this.users });
  }

  @Mutation()
  public commitUpdatePage(payload: {
    page: auth.UserRecord[];
    userData: UserRole;
    pageIndex: number;
  }) {
    payload.page.forEach((record) => {
      if (record.uid === payload.userData.user) {
        record.customClaims['role'] = payload.userData.role;
      }
    });

    this.commitUserPages({
      nextToken: this.nextPage,
      pages: { [payload.pageIndex]: payload.page },
    });
  }

  @Action()
  public dispatchAdminUsers() {
    adminService.getUsers(this.root.getToken, this.nextPage).subscribe({
      next: (ajax) => {
        const startKey = Object.keys(this.users).length + 1;
        const userRes: auth.ListUsersResult = ajax.response;
        const userState = chunkUsers(startKey, userRes.users);

        this.commitUserPages({
          nextToken: userRes.pageToken || '',
          pages: userState,
        });
      },
    });
  }

  @Action()
  public dispatchUpdateRole(payload: {
    roleUpdate: UserRole;
    currentPage: number;
  }) {
    adminService.setRole(this.root.getToken, payload.roleUpdate).subscribe({
      next: (res) => {
        logger.adminStore.info('Success', res);
        const group = [...this.users[payload.currentPage]];

        this.commitUpdatePage({
          page: group,
          userData: payload.roleUpdate,
          pageIndex: payload.currentPage,
        });
      },
    });
  }
}

export default AdminStore;
