












































































import type { UserState } from '@homestyle/shared-data';
import { UserRoles } from '@homestyle/shared-data';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import {
	registerModule,
	unregisterModule,
	useModule,
	useStore,
} from 'vuex-simple';

import { AdminStore } from './store';
import { MyStore } from '../store';
import {
	EventBusService,
	EventBus,
} from '../shared/services/event-bus.service';
import { AdminEvents } from '../shared/utils/admin-event.enum';

@Component({
	metaInfo: {
		title: 'Admin Dashboard',
	},
})
export class Admin extends Vue {
	public get adminStore(): AdminStore {
		return useModule<AdminStore>(this.$store, ['adminStore']);
	}
	public current = 1;
	public get options(): { text: string; value: UserRoles }[] {
		const values = Object.values(UserRoles).filter(
			(value) => value !== UserRoles.Anonymous
		);

		return values.map((value) => ({
			text: this.formatRole(value),
			value: value,
		}));
	}
	public get pages() {
		return { ...this.usersState };
	}
	public store: MyStore = useStore(this.$store);

	private accessUpdates: { [key: string]: UserRoles } = {};
	private usersState: UserState = {};

	public created(): void {
		EventBusService.on(AdminEvents.users, this.updatePages);
		registerModule(this.$store, ['adminStore'], new AdminStore(this.store));
		this.adminStore.dispatchAdminUsers();
	}

	public destroyed() {
		unregisterModule(this.$store, ['adminStore']);
	}

	public formatDate(date: string | null) {
		let formated = 'Not Active';
		if (date) {
			formated = moment(date).format('LLL');
		}

		return formated;
	}

	public formatRole(role: string) {
		const first = role.slice(0, 1);

		return role.replace(first, first.toUpperCase());
	}

	public getNextPages() {
		const endNext = this.current >= Object.keys(this.pages).length - 1;
		if (this.adminStore.adminState.nextPage && endNext) {
			this.adminStore.dispatchAdminUsers();
		}
	}

	public updateSelection(value: UserRoles, uid: string) {
		this.accessUpdates[uid] = value;
	}

	private updatePages(userEvent: EventBus) {
		if (userEvent.event === AdminEvents.users) {
			this.usersState = userEvent.payload as UserState;
		}
	}

	private saveAccessChanges(uid: string) {
		const accessUpdate = {
			user: uid,
			role: this.accessUpdates[uid],
		};

		if (accessUpdate.role) {
			this.adminStore.dispatchUpdateRole({
				roleUpdate: accessUpdate,
				currentPage: this.current,
			});
		}
	}
}

export default Admin;
