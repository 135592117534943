









import Vue from 'vue';

export const Gallery = Vue.extend({});

export default Gallery;
