












































































import { UserRoles } from '@homestyle/shared-data';
import { mdiMenu } from '@mdi/js';
import ClientOnly from 'vue-client-only';
import { Component, Vue } from 'vue-property-decorator';

import LogoImage from '../LogoImage';
import { NavNode } from '../../models/nav-node.model';
import { getNav } from '../../../router';

@Component({
	components: {
		ClientOnly,
		LogoImage,
	},
})
export class NavbarComponent extends Vue {
	public drawer = false;
	public menu = mdiMenu;
	public get nodes(): NavNode[] {
		return getNav(this.role);
	}

	private get role(): UserRoles {
		const { currentUser } = this.$store.getters;
		const newRole = currentUser.role;

		return newRole;
	}

	public toggleMenu() {
		this.drawer = !this.drawer;
	}
}

export default NavbarComponent;
