import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

export interface ImgSize {
  height: number;
  width: number;
}

interface State {
  fontSize: number;
  html: string;
  imgLib: string[];
  naturalSize: ImgSize | null;
  selected: number | null;
  showFontMark: boolean;
  resizeActive: boolean;
  url: string;
}

const initialState: State = {
  fontSize: 16,
  showFontMark: false,
  html: '',
  imgLib: [],
  naturalSize: null,
  selected: null,
  resizeActive: false,
  url: '',
};

let initialFontSize = 16;

const storeRedux = new Subject<State>();

const globalStore = {
  state: initialState,
};

const getState = () => globalStore.state;

const contentRegex = /(contenteditable="true"|<p><\/p>)/gi;

export const filterContent = (content: string) => {
  const cleanContent = content.replace(contentRegex, '');

  return cleanContent;
};

export const storeService = {
  getState: getState,
  initialFontSize: (size = 16) => {
    initialFontSize = size;
    storeService.setFontSize();
  },
  setContent: (html: string) => {
    globalStore.state = { ...getState(), html };
    storeRedux.next(globalStore.state);
  },
  setFontSize: (fontSize = initialFontSize) => {
    globalStore.state = { ...getState(), fontSize };
    storeRedux.next(globalStore.state);
  },
  setImgLib: (imgLib: string[]) => {
    globalStore.state = { ...getState(), imgLib };
    storeRedux.next(globalStore.state);
  },
  setInit: (state: Partial<State>) => {
    globalStore.state = { ...initialState, ...state };
    storeRedux.next(globalStore.state);
  },
  setSelect: (index: number | null, size: ImgSize | null) => {
    const { state } = globalStore;
    globalStore.state = { ...getState(), naturalSize: size, selected: index, url: state.imgLib[index] || '' };
    storeRedux.next(globalStore.state);
  },
  setResize: (resizeActive: boolean) => {
    globalStore.state = { ...getState(), resizeActive };
    storeRedux.next(globalStore.state);
  },
  toggleFontMark: (showFontMark: boolean) => {
    globalStore.state = { ...getState(), showFontMark };
    storeRedux.next(globalStore.state);
  },
};

export const useStore = () => {
  const [state, setState] = useState(getState);

  useEffect(() => {
    const sub = storeRedux.subscribe(setState);

    return () => sub.unsubscribe();
  }, []);

  return state;
};
