import { PartialObserver, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { logger } from '../utils/logger';

interface EventBus {
  event: string;
  payload?: unknown;
}

export type { EventBus };

export class EventBusService {
  private static eventBus$: Subject<EventBus> = new Subject();

  public static emit(action: EventBus) {
    this.eventBus$.next(action);
  }

  public static on(target = '', stream: (value?: EventBus) => void) {
    const observer: PartialObserver<EventBus> = {
      error: this.logError,
      next: stream,
    };

    this.eventBus$
      .pipe(
        filter((evt) => evt.event === target),
        map((evt) => evt)
      )
      .subscribe(observer);
  }

  private static logError(err: unknown) {
    logger.eventError.error(err);
  }
}

export default EventBusService;
