import * as React from 'react';

import { ImageItem } from '../../components/ImageItem';
import { useOutsideDismiss } from '../../hooks/outside-dismiss.hook';
import { storeService, useStore } from '../../store';
import { setResize } from '../../utils/resize.util';
import './ImageModal.scss';

export interface ImageModalProps {
  fig?: Node;
  modalWrapper: HTMLDivElement;
  onInsert: () => void;
}

export const ImageModal = ({ fig, modalWrapper, onInsert }: ImageModalProps): JSX.Element => {
  const resizeDiv = React.useRef(null);
  const { imgLib, resizeActive } = useStore();
  const modalRef = useOutsideDismiss(modalWrapper);

  const close = () => {
    document.body.removeChild(modalWrapper);
    storeService.setResize(false);
  };

  React.useEffect(() => {
    if (resizeActive) {
      const outerDiv: HTMLDivElement = resizeDiv.current;

      if (outerDiv && fig) {
        outerDiv.appendChild(fig);
        setResize(fig as HTMLImageElement);
      }
    }
  }, [resizeActive, fig]);

  return (
    <div ref={modalRef} className="ImageModal">
      <header className="imageHeader">
        <h3>{resizeActive ? 'Resize' : 'Select'} an Image</h3>
        <div className="imageCloseBtn" onClick={close}>
          <span className="material-icons">clear</span>
        </div>
      </header>
      <section className={`imageBody ${resizeActive && 'imageResize'}`}>
        {resizeActive ? (
          <div ref={resizeDiv} className={`imageLibWrapper imageResize`} />
        ) : (
          <div className="imageLibWrapper">
            {imgLib.map((img, index) => (
              <ImageItem key={`hs-image-${index}`} url={img} selector={index} />
            ))}
          </div>
        )}
      </section>
      <footer className="imageAction">
        <div className={`imageActionBtns imageCancelBtn`} onClick={close}>
          Cancel
        </div>
        <div
          className={`imageActionBtns imageActionBtn`}
          onClick={() => {
            close();
            onInsert();
          }}
        >
          {resizeActive ? 'Apply' : 'Insert'}
        </div>
      </footer>
    </div>
  );
};

export default ImageModal;
