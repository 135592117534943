import { MetaInfo } from 'vue-meta';

export const metaTags: MetaInfo = {
  meta: [
    {
      content: 'Quality workmanship at honest prices for your upholstery needs.',
      name: 'description',
      vmid: 'desc',
    },
    {
      content: 'https://homestylecustom.com/assets/images/homestylelogo.png',
      name: 'image',
      property: 'image',
      vmid: 'image',
    },
    {
      content: 'Homestyle Custom Upholstery',
      name: 'name',
      property: 'name',
      vmid: 'name',
    },
    {
      content: 'Homestyle Custom Upholstery',
      property: 'og:title',
      vmid: 'og-title',
    },
    {
      content: 'Quality workmanship at honest prices for your upholstery needs.',
      property: 'og:description',
      vmid: 'og-desc',
    },
    {
      content: 'https://homestylecustom.com/assets/images/homestylelogo.png',
      property: 'og:image',
      vmid: 'og-image',
    },
    {
      content: 'https://homestylecustom.com',
      property: 'og:url',
      vmid: 'og-url',
    },
    {
      content: '',
      name: 'og:site_name',
      vmid: 'og-site',
    },
    {
      content: 'website',
      property: 'og:type',
      vmid: 'og-type',
    },
  ],
};
