import { getGuid } from '@homestyle/shared-data';
import firebase from 'firebase/app';
import 'firebase/analytics';

import { environment } from '../../../environments/environment';

const { firebaseConfig } = environment;

export const firebaseSetup = async () => {
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (error) {
    const name = `homestyle-${getGuid()}`;

    firebase.initializeApp(firebaseConfig, name);
  }

  if (!process.env.VUE_APP_SSR) firebase.analytics();
};

export default firebaseSetup;
