import type { MediaItem, PhotoItem } from '@homestyle/shared-data';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { logger } from '../utils/logger';
import { ajax } from './http-client.service';

export class PageService {
  public getGalleryPage(name: string) {
    const url = `/api/galleries/${name}`;

    return ajax.request({ method: 'get', url }).pipe(
      catchError((err) => {
        logger.storeLog.error('Error Occured Retriving Data: ', err);

        return throwError(err);
      })
    );
  }

  public getPage(name: string) {
    const url = `/api/pages/${name}`;

    return ajax.request({ method: 'get', url }).pipe(
      catchError((err) => {
        logger.storeLog.error('Error Occured Retriving Data: ', err);

        return throwError(err);
      })
    );
  }

  public setPhotoItems(media: MediaItem[]) {
    const galleryPhotos: PhotoItem[] = media.map((image) => ({
      creationDate: image.mediaMetadata.creationTime,
      desc: image.description || '',
      file: image.filename,
      id: image.id,
      url: image.baseUrl,
    }));

    return galleryPhotos;
  }
}

export const pageService = new PageService();

export default PageService;
