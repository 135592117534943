export enum AppEvents {
  assets = 'app-assets',
  complete = 'app-complete',
  message = 'app-message',
  save = 'app-save',
  sync = 'app-sync-gallery',
}

export const AppEventLimit = {
  complete: true,
  message: true,
  sync: true,
};

export enum AppMessages {
  messageError = 'Sorry, Your message failed to send.',
  messageSent = 'Your message was sent, Thank you.',
  photoError = 'Sorry, Photo sync failed to complete.',
  photosSync = 'Photo sync has been completed.',
  savePage = 'Page content has been Saved.',
  saveError = 'Error while saving page Content.',
}

export default AppEvents;
