import { UserRoles } from '@homestyle/shared-data';
import Vue from 'vue';
import VueMetaPlugin from 'vue-meta';
import Router from 'vue-router';

import { adminRoutes } from './Admin';
import { galleryRoutes } from './Gallery';
import { RouteConfig } from './shared/models/meta-tag.model';
import { NavNode } from './shared/models/nav-node.model';

Vue.use(Router);
Vue.use(VueMetaPlugin);

const appRoutes: RouteConfig[] = [
  {
    component: () => import('./Page'),
    meta: {
      audience: [UserRoles.Member, UserRoles.Admin],
    },
    name: 'home',
    path: '/',
  },
  {
    component: () => import('./Gallery'),
    children: galleryRoutes,
    meta: {
      audience: [UserRoles.Member, UserRoles.Admin],
    },
    path: '/gallery',
  },
  {
    component: () => import('./ContactUs'),
    meta: {
      audience: [UserRoles.Member, UserRoles.Admin],
    },
    name: 'contact us',
    path: '/contact',
  },
  {
    component: () => import('./Work'),
    meta: {
      audience: [UserRoles.Member, UserRoles.Admin],
    },
    name: 'request work',
    path: '/work',
  },
];

const systemRoutes: RouteConfig[] = [
  {
    component: () => import('./AuthPage'),
    meta: {
      audience: [UserRoles.Anonymous],
    },
    name: 'Login',
    path: '/login',
  },
  {
    component: () => import('./NotFound'),
    meta: {
      audience: [UserRoles.Anonymous],
    },
    name: 'not found',
    path: '*',
  },
];

const routes = [...appRoutes, ...adminRoutes];

const roleFilter = (route: RouteConfig, role: UserRoles) =>
  route.meta.audience.includes(role) ||
  route.meta.audience.includes(UserRoles.Anonymous);

export const getNav = (role: UserRoles = UserRoles.Anonymous): NavNode[] => {
  return routes
    .filter((route) => roleFilter(route, role))
    .map((route, index) => ({
      id: index,
      name: route.name || route.path.split('/').find((part) => /\D/.test(part)),
      path: route.path,
    }));
};

export const pages = () => routes.map((route) => route.name);

export const createRouter = () => {
  return new Router({
    mode: 'history',
    routes: [...routes, ...systemRoutes],
  });
};

export default createRouter;
