export enum AuthEvents {
  action = 'auth-action',
  close = 'auth-close',
  complete = 'auth-complete',
  create = 'auth-create',
  form = 'auth-form',
  google = 'auth-google',
}

export enum AuthMessages {
  access = 'Access Denied!',
  created = 'Account Created.',
  failedCreate = 'Sorry, we could not create user.',
  failedLogin = 'Sorry, there an issue with the User Name and/or Pasword.',
  successLogin = 'Login Successful.',
  userLogout = 'User Succesfully Logged Out.',
}

export const AuthEventLimit = {
  action: true,
  close: true,
  complete: true,
  create: true,
  form: true,
  google: true,
};

export default AuthEvents;
