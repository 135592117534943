import type {
  AlbumOption,
  GalleryPage,
  HTMLObject,
} from '@homestyle/shared-data';
import { CollectionIds } from '@homestyle/shared-data';
import { Action, Getter, Mutation, State } from 'vuex-simple';

import { MyStore } from '../../store';
import { adminService } from '../../shared/services/admin.service';
import { EventBusService } from '../../shared/services/event-bus.service';
import { photosService } from '../../shared/services/photos.service';
import { pageService } from '../../shared/services/page.service';
import { AppEvents, AppMessages } from '../../shared/utils/app-event.enum';
import { logger } from '../../shared/utils/logger';

export class GalleryStore {
  @State()
  protected albumOptions: AlbumOption[] = [];

  @State()
  protected galleryPage: GalleryPage = {
    album: '',
    htmlContent: [],
    name: '',
    photos: [],
  };

  @Getter()
  public get getAlbumOptions() {
    return this.albumOptions;
  }

  @Getter()
  public get getGalleryPage() {
    return this.galleryPage;
  }

  public get getGalleryContent() {
    return this.galleryPage.htmlContent;
  }

  constructor(private root: MyStore) {}

  @Mutation()
  public commitAlbums(albumOpts: AlbumOption[]) {
    this.albumOptions = albumOpts;
  }

  @Mutation()
  public commitGalleryPage(page: Partial<GalleryPage>) {
    this.galleryPage = { ...this.galleryPage, ...page };
  }

  @Action()
  public async dispatchAlbums() {
    try {
      logger.storeLog.info('Gallery', 'getting Albums');
      const sharedAlbums = await photosService.getAlbums();
      const options = sharedAlbums.map((album) => ({
        id: album.id,
        name: album.title,
        shareUrl: album.shareInfo && album.shareInfo.shareableUrl,
      }));
      logger.storeLog.info('Gallery', 'setting Albums');
      this.commitAlbums(options);
    } catch (error) {
      logger.storeLog.error('Gallery', 'Failed Getting Albums', error);
    }
  }

  @Action()
  public distpatchContent(payload: HTMLObject[]) {
    adminService
      .savePage(CollectionIds.galleries, this.root.getToken, {
        ...this.galleryPage,
        htmlContent: payload,
      })
      .subscribe((res) => {
        logger.storeLog.info('Gallery', 'Update Complete', res);
        this.commitGalleryPage({ htmlContent: payload });
        EventBusService.emit({ event: AppEvents.complete });
      });
  }

  @Action()
  public async dispatchGalleryPage(name: string) {
    try {
      logger.storeLog.info('Gallery', 'Getting Page');
      const page = await pageService.getGalleryPage(name).toPromise();
      this.commitGalleryPage(page.data);
    } catch (error) {
      logger.storeLog.error('Gallery', error);
    }
  }

  @Action()
  public async dispatchPhotos(payload: {
    id: string;
    name: string;
    shareUrl: string;
  }) {
    try {
      logger.storeLog.info('Gallery', 'getting Images');
      const images = await photosService.getPhotos(payload.id);
      const galleryPhotos = pageService.setPhotoItems(images);

      adminService
        .savePage(
          CollectionIds.galleries,
          this.root.getToken,
          {
            ...this.galleryPage,
            album: payload.name,
            photos: galleryPhotos,
            shareUrl: payload.shareUrl,
          },
          true
        )
        .subscribe((res) => {
          logger.storeLog.info('Gallery', 'Update Complete', res);
          EventBusService.emit({
            event: AppEvents.complete,
            payload: AppMessages.photosSync,
          });
          this.commitGalleryPage({
            album: payload.name,
            photos: galleryPhotos,
          });
        });
    } catch (error) {
      logger.storeLog.error('Gallery', error);
      EventBusService.emit({
        event: AppEvents.complete,
        payload: AppMessages.photoError,
      });
    }
  }
}

export default GalleryStore;
