import { UserRoles } from '@homestyle/shared-data';

import { RouteConfig } from '../shared/models/meta-tag.model';

export const galleryRoutes: RouteConfig[] = [
  {
    component: () => import('./GalleryHome'),
    meta: { audience: [UserRoles.Anonymous] },
    name: 'gallery-home',
    path: '/',
  },
  {
    component: () => import('./GalleryPage'),
    meta: {
      audience: [UserRoles.Anonymous],
      title: 'Boats | Gallery',
    },
    name: 'boats',
    path: 'boats',
  },
  {
    component: () => import('./GalleryPage'),
    meta: {
      audience: [UserRoles.Anonymous],
      title: 'Cars | Gallery ',
    },
    name: 'automotive',
    path: 'cars',
  },
  {
    component: () => import('./GalleryPage'),
    meta: {
      audience: [UserRoles.Anonymous],
      title: 'Motorcycle | Gallery',
    },
    name: 'motorcycles',
    path: 'bikes',
  },
  {
    component: () => import('./GalleryPage'),
    meta: {
      audience: [UserRoles.Anonymous],
      title: 'Home Furnishing | Gallery',
    },
    name: 'home furnishing',
    path: 'furniture',
  },
  {
    component: () => import('./GalleryPage'),
    meta: {
      audience: [UserRoles.Anonymous],
      title: 'Miscellaneous | Gallery',
    },
    name: 'miscellaneous',
    path: 'misc',
  },
];

const getIcon = (path: string) => {
  const galleryIcons = {
    bikes: 'motorcycle',
    boats: 'directions_boat',
    cars: 'directions_car',
    // misc: 'build',
    misc: 'flight',
    furniture: 'house',
  };

  return galleryIcons[path];
};

export const galleryNav = () => {
  const subRoutes = galleryRoutes.filter((route) => route.name !== 'gallery-home');

  return subRoutes.map((route, index) => ({
    icon: getIcon(route.path),
    id: index,
    name: route.name,
    path: route.path,
  }));
};

export default galleryRoutes;
