










import type { HTMLObject } from '@homestyle/shared-data';
import Vue from 'vue';
import { useModule, useStore } from 'vuex-simple';

import { PageStore } from './store';
import { MyStore } from '../store';
import Editor from '../shared/containers/Editor';
import PreviewButton from '../shared/components/PreviewButton';
import {
	EventBusService,
	EventBus,
} from '../shared/services/event-bus.service';
import { AppEvents } from '../shared/utils/app-event.enum';

export const Page = Vue.extend({
	beforeMount: function () {
		if (!this.isPrefetched) {
			const pageName = this.$route.name;
			this.pageStore.dispatchPage(pageName);
		}
	},
	components: {
		Editor,
		PreviewButton,
	},
	created: function () {
		EventBusService.on(AppEvents.save, this.saveContent);
	},
	data: function () {
		const isPrefetched = false;
		const pageStore: PageStore = useModule(this.$store, ['page']);
		const store: MyStore = useStore(this.$store);

		return {
			isPrefetched,
			pageStore,
			store,
		};
	},
	methods: {
		saveContent: function (eventBus: EventBus) {
			this.pageStore.distpatchContent(eventBus.payload as HTMLObject[]);
		},
	},
	serverPrefetch: async function () {
		const pageName = this.$route.name;
		this.$data.isPrefetched = true;

		await this.$data.pageStore.dispatchPage(pageName);
	},
});

export default Page;
