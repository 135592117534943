import Vue from 'vue';
import Vuetify from 'vuetify';
import {
  VAlert,
  VApp,
  VAvatar,
  VBtn,
  VCard,
  VCardActions,
  VCardTitle,
  VCardText,
  VCarousel,
  VCarouselItem,
  VCol,
  VContainer,
  VDialog,
  VDivider,
  VFileInput,
  VFooter,
  VForm,
  VImg,
  VIcon,
  VList,
  VListItem,
  VListItemContent,
  VMain,
  VNavigationDrawer,
  VPagination,
  VProgressLinear,
  VRow,
  VSelect,
  VSnackbar,
  VSpacer,
  VTab,
  VTabs,
  VTabItem,
  VTextarea,
  VTextField,
  VToolbar,
  VToolbarItems,
} from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAvatar,
    VBtn,
    VCard,
    VCardActions,
    VCardTitle,
    VCardText,
    VCarousel,
    VCarouselItem,
    VCol,
    VContainer,
    VDialog,
    VDivider,
    VFileInput,
    VFooter,
    VForm,
    VIcon,
    VImg,
    VList,
    VListItem,
    VListItemContent,
    VMain,
    VNavigationDrawer,
    VPagination,
    VProgressLinear,
    VRow,
    VSelect,
    VSnackbar,
    VSpacer,
    VTab,
    VTabs,
    VTabItem,
    VTextarea,
    VTextField,
    VToolbar,
    VToolbarItems,
  },
  directives: {
    Ripple,
  },
});

export const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        accent: '#d10054',
        primary: '#6494b5',
        grey: '#bdbdbd',
      },
    },
  },
});

export default vuetify;
