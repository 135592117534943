export const firebaseConfig = {
  apiKey: 'AIzaSyD7FLkcUV7vzdRJOTqOnb0ylPh-dHk1e1A',
  authDomain: 'homestyle-dev-7c526.firebaseapp.com',
  databaseURL: 'https://homestyle-dev-7c526.firebaseio.com',
  projectId: 'homestyle-dev-7c526',
  storageBucket: 'homestyle-dev-7c526.appspot.com',
  messagingSenderId: '300701283448',
  appId: '1:300701283448:web:1d1c685aeb94dbccf4e98d',
  measurementId: 'G-STK55VS39T',
  clientId:
    '300701283448-45fose359p3hv5spjepgv46guihb780j.apps.googleusercontent.com',
  scopes: ['https://www.googleapis.com/auth/photoslibrary.sharing'],
  discoveryDocs: [
    'https://content.googleapis.com/discovery/v1/apis/photoslibrary/v1/rest',
  ],
};
