import type { HTMLObject, PageModel } from '@homestyle/shared-data';
import { CollectionIds } from '@homestyle/shared-data';
import { Action, Getter, Mutation, State } from 'vuex-simple';

import { MyStore } from '../../store';
import { adminService } from '../../shared/services/admin.service';
import { EventBusService } from '../../shared/services/event-bus.service';
import { photosService } from '../../shared/services/photos.service';
import { pageService } from '../../shared/services/page.service';
import { AppEvents, AppMessages } from '../../shared/utils/app-event.enum';
import { logger } from '../../shared/utils/logger';

export class PageStore {
  @State()
  protected page: PageModel = {
    assets: [],
    htmlContent: [],
    name: '',
  };

  @Getter()
  public get getAssets() {
    return this.page.assets;
  }

  @Getter()
  public get getPageContent() {
    return this.page.htmlContent;
  }

  constructor(private root: MyStore) {}

  @Mutation()
  public commitAssets(assets: string[]) {
    this.page = { ...this.page, assets };
  }

  @Mutation()
  public commitPage(page: Partial<PageModel>) {
    this.page = { ...this.page, ...page };
  }

  @Action()
  public async dispatchAssetsImages() {
    try {
      logger.storeLog.info('Page', 'Getting Asset Library');
      const albums = await photosService.getAlbums();
      const assetAlbum = albums.find((album) => album.title === 'Web-Assets');
      const images = await photosService.getPhotos(assetAlbum.id);

      adminService
        .saveAssets(this.root.getToken, {
          photos: images.map((image) => ({
            creationDate: image.mediaMetadata.creationTime,
            desc: image.description,
            file: image.filename,
            id: image.id,
            url: image.baseUrl,
          })),
          shareUrl: assetAlbum.shareInfo.shareableUrl,
        })
        .subscribe((data) => {
          logger.storeLog.info('Page', 'Assets finished', data);
          EventBusService.emit({
            event: AppEvents.complete,
            payload: AppMessages.photosSync,
          });

          const { response } = data;
          const assetData = Array.isArray(response?.photo)
            ? response.photo
            : [];
          const assets = assetData.map((asset) => asset.url);

          this.commitAssets(assets);
        });
    } catch (error) {
      logger.storeLog.error('Page', 'Assets Image Error', error);
      EventBusService.emit({
        event: AppEvents.complete,
        payload: AppMessages.photoError,
      });
    }
  }

  @Action()
  public async dispatchAssetsImageUrls() {
    logger.storeLog.info('Page', 'Getting Assets from Firestore');

    adminService.getAssets(this.root.getToken).subscribe((data) => {
      logger.storeLog.info('Page', 'Get Assets finished', data);
      const assetsData = Array.isArray(data.response) ? data.response : [];
      const assetsUrls: string[] = assetsData.map((asset) => asset.url);

      this.commitAssets(assetsUrls);
    });
  }

  @Action()
  public distpatchContent(payload: HTMLObject[]) {
    adminService
      .savePage(CollectionIds.pages, this.root.getToken, {
        ...this.page,
        htmlContent: payload,
      })
      .subscribe((res) => {
        logger.storeLog.info('Page', 'Update Complete', res);
        this.commitPage({ htmlContent: payload });
        EventBusService.emit({
          event: AppEvents.complete,
          payload: AppMessages.savePage,
        });
      });
  }

  @Action()
  public async dispatchPage(name: string) {
    try {
      this.commitPage({ name });
      logger.storeLog.info('Page', 'Getting Page');
      const page = await pageService.getPage(name).toPromise();
      this.commitPage(page.data);
    } catch (error) {
      logger.storeLog.error('Page', error);
    }
  }
}

export default PageStore;
