import { useEffect, useRef } from 'react';
import { fromEvent } from 'rxjs';

import { storeService, useStore } from '../store';

export const buttonId = 'hsResizeButton';

export const useOutsideCancel = () => {
  const button = document.getElementById(buttonId);
  const ref = useRef<HTMLDivElement>(null);
  const { showFontMark } = useStore();

  useEffect(() => {
    const resizeRef = ref.current;
    const sub = fromEvent(document.body, 'click').subscribe(evt => {
      const target = evt.target as HTMLElement;

      if (resizeRef && !resizeRef.contains(target)) {
        storeService.setFontSize();

        if (!button.contains(target)) {
          storeService.toggleFontMark(false);
        }
      }
    });

    return () => sub.unsubscribe();
  }, [showFontMark, button]);

  return ref;
};
