import { toggleMark } from 'prosemirror-commands';
import { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import * as React from 'react';
import { Extension, markActive } from 'smartblock';
import { getMarkInSelection } from 'smartblock/lib/utils';
import { Dispatch, ExtensionProps } from 'smartblock/lib/types';

import { FontSizeButton, markEvent } from '../../containers/FontSizeButton';
import { storeService } from '../../store';
import { ExtensionSchema } from '../../utils';

export class FontSize extends Extension {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props?: ExtensionProps) {
    super(props);
  }

  private get showFontMark() {
    const { showFontMark } = storeService.getState();

    return showFontMark;
  }

  public get group() {
    return 'mark';
  }

  public get icon() {
    return <FontSizeButton />;
  }

  public get name() {
    return 'fontSize';
  }

  public get schema(): ExtensionSchema {
    return {
      attrs: {
        size: { default: 1 },
      },
      group: 'mark',
      parseDOM: [
        {
          tag: 'span',
          getAttrs: dom => {
            const size = Number(dom.getAttribute('data-size'));

            return {
              size,
            };
          },
        },
      ],
      toDOM: (node: Node) => {
        return [
          'span',
          {
            'data-size': node.attrs.size,
            style: `font-size: ${node.attrs.size}rem`,
          },
          0,
        ];
      },
    };
  }

  public get showMenu() {
    return true;
  }

  public active(state: EditorState) {
    return this.showFontMark || this.hasFontMark(state);
  }

  public onClick(state: EditorState, dispatch: Dispatch) {
    if (!this.showFontMark) {
      const size = this.getSetSize(state);
      storeService.initialFontSize(size);
    }

    if (this.hasFontMark(state)) {
      this.toggleMark(state, dispatch);
    } else {
      storeService.toggleFontMark(!this.showFontMark);
      this.setMark(state, dispatch);
    }
  }

  private getSetSize(state: EditorState): number {
    const sizeMark = getMarkInSelection('fontSize', state);

    return sizeMark ? sizeMark.attrs.size * 16 : 16;
  }

  private hasFontMark(state: EditorState) {
    return markActive(state.schema.marks.fontSize)(state);
  }

  private setMark(state: EditorState, dispatch: Dispatch) {
    const sub = markEvent.subscribe(() => {
      this.toggleMark(state, dispatch);
      storeService.toggleFontMark(false);
      sub.unsubscribe();
    });
  }

  private toggleMark(state: EditorState, dispatch: Dispatch) {
    const { fontSize } = storeService.getState();
    const size = fontSize / 16;

    toggleMark(state.schema.marks.fontSize, { size })(state, dispatch);
  }
}
