import { UserRoles } from '@homestyle/shared-data';
import { NavigationGuard, Route } from 'vue-router';

import { EventBusService } from '../app/shared/services/event-bus.service';
import { store } from '../app/store';
import { AuthEvents, AuthMessages } from '../app/shared/utils/auth-event.enum';

export const authGuard: NavigationGuard = (
  dest: Route,
  _init: Route,
  next: (path?: string) => void
) => {
  const record = dest.matched
    .slice()
    .reverse()
    .find((route) => route && route.meta && route.meta.audience);
  const audiences: UserRoles[] = (record && record.meta.audience) || [];

  if (
    audiences.includes(UserRoles.Anonymous) ||
    audiences.includes(store.getters.currentUser.role)
  ) {
    next();
  } else {
    EventBusService.emit({
      event: AuthEvents.complete,
      payload: AuthMessages.access,
    });
    let path = '/';

    if (record && record.path !== '') {
      path = record.path;
    }

    next(`/login?redirect=${path}`);
  }
};

export default authGuard;
