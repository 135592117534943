import type { UserRole } from '@homestyle/shared-data';
import { CollectionIds } from '@homestyle/shared-data';
import type { auth } from 'firebase-admin';
import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import type { AjaxConfig, AjaxResponse } from 'rxjs/ajax';
import type { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { setAuthHeaders } from '../utils/auth-header.helper';
import { AppEvents, AppMessages } from '../utils/app-event.enum';
import { logger } from '../utils/logger';
import EventBusService from './event-bus.service';

export class AdminService {
  public getAssets(token: string) {
    const url = `/api/admin/assets`;
    const request = {
      headers: { ...setAuthHeaders(token), 'Content-Type': 'application/json' },
      method: 'get',
      url,
    };
    logger.adminStore.info('Getting Assets');

    return this.saveFirestore(request);
  }

  public getUsers(token: string, nextToken?: string) {
    const url = nextToken
      ? `/api/admin/users?page=${nextToken}`
      : '/api/admin/users';
    logger.adminStore.info('Calling Admin services');

    return ajax({ headers: setAuthHeaders(token), method: 'get', url }).pipe(
      catchError((err) => {
        logger.adminStore.error('Error Occured Retriving Data: ', err);

        return throwError(err);
      })
    ) as Observable<AjaxResponse<auth.ListUsersResult>>;
  }

  public setRole(token: string, userData: UserRole) {
    const url = '/api/admin/role';
    const request = {
      body: userData,
      headers: setAuthHeaders(token),
      method: 'put',
      url,
    };
    logger.adminStore.info(
      `Updating User Role to ${userData.role} for id ${userData.user}`
    );

    return this.saveFirestore(request);
  }

  public saveAssets(token: string, page: unknown) {
    const url = `/api/admin/assets`;
    const request = {
      body: page,
      headers: { ...setAuthHeaders(token), 'Content-Type': 'application/json' },
      method: 'put',
      url,
    };
    logger.adminStore.info('Updating Assets', page);

    return this.saveFirestore(request) as Observable<
      AjaxResponse<{ photo: { url: string[] } }>
    >;
  }

  public savePage(
    collection: CollectionIds,
    token: string,
    page: unknown,
    isSync = false
  ) {
    const url = isSync
      ? '/api/admin/galleries'
      : `/api/admin/pages/${collection}`;
    const request = {
      body: page,
      headers: { ...setAuthHeaders(token), 'Content-Type': 'application/json' },
      method: 'put',
      url,
    };
    logger.adminStore.info('Updating Page', page);

    return this.saveFirestore(request, AppMessages.saveError);
  }

  private saveFirestore(request: AjaxConfig, message?: AppMessages) {
    return ajax(request).pipe(
      catchError((err) => {
        logger.adminStore.error('Error Occured Updating Data: ', err);
        if (message && message === AppMessages.saveError) {
          EventBusService.emit({ event: AppEvents.complete, payload: message });
        }

        return throwError(err);
      })
    );
  }
}

export const adminService = new AdminService();

export default adminService;
