import type { AuthFormModal, CurrentUser, TokenManagerModel } from '@homestyle/shared-data';
import { auth } from 'firebase-admin';
import firebase from 'firebase/app';
import 'firebase/auth';
import { from, of, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, take } from 'rxjs/operators';

import { setAuthHeaders } from '../utils/auth-header.helper';
import { AuthMessages } from '../utils/auth-event.enum';
import { AppEvents } from '../utils/app-event.enum';
import { logger } from '../utils/logger';
import { EventBusService } from './event-bus.service';

export class AuthService {
  public checkEmail(email: string) {
    return ajax({ body: { email }, method: 'post', url: '/api/Oauth2/validate' });
  }

  public createUser(user: AuthFormModal) {
    logger.authStore.info('User', user);
    const newUser = {
      email: user.userName,
      password: atob(user.password),
    };

    return ajax({ body: newUser, method: 'post', url: '/api/Oauth2/user' }).pipe(
      catchError((err) => {
        EventBusService.emit({
          event: AppEvents.complete,
          payload: AuthMessages.failedCreate,
        });

        return this.handleError(err);
      }),
      take(1)
    ) as Observable<auth.UserRecord>;
  }

  public signIn(useGoogle: boolean, login: AuthFormModal) {
    if (useGoogle) {
      logger.authStore.info('Signin user by Google');

      return from(this.siginWithGoogle()).pipe(
        catchError((err) => {
          EventBusService.emit({
            event: AppEvents.complete,
            payload: AuthMessages.failedLogin,
          });

          return this.handleError(err);
        })
      ) as Observable<CurrentUser>;
    } else {
      logger.authStore.info('Signin user by email');

      return from(this.signWithEmail(login)).pipe(
        catchError((err) => {
          EventBusService.emit({
            event: AppEvents.complete,
            payload: AuthMessages.failedLogin,
          });

          return this.handleError(err);
        })
      ) as Observable<CurrentUser>;
    }
  }

  public signOut() {
    return from(firebase.auth().signOut()).pipe(
      catchError((err) => this.handleError(err)),
      take(1)
    );
  }

  private async checkRole(creds: firebase.auth.UserCredential) {
    try {
      logger.authStore.info('Checking for Users Role.');
      const user = await creds.user.getIdTokenResult();

      return {
        role: (user.claims.role as string) || '',
      };
    } catch (error) {
      logger.authStore.error(error);

      throw error;
    }
  }

  private handleError(err: unknown) {
    logger.authStore.error('Auth Error');
    logger.authStore.error(err);

    return of(err);
  }

  private async signWithEmail(login: AuthFormModal) {
    try {
      const creds = await firebase.auth().signInWithEmailAndPassword(login.userName, atob(login.password));
      logger.authStore.info(creds);
      const userRole = await this.checkRole(creds);

      return userRole;
    } catch (error) {
      logger.authStore.error(error);

      throw error;
    }
  }

  private async siginWithGoogle() {
    const google = new firebase.auth.GoogleAuthProvider();
    try {
      const creds = await firebase.auth().signInWithPopup(google);
      logger.authStore.info(creds);
      let userRole = await this.checkRole(creds);
      if (!userRole.role) {
        userRole = await this.updateRole(creds.user);
      }

      return userRole;
    } catch (error) {
      logger.authStore.error(error);

      throw error;
    }
  }

  private async updateRole(user: firebase.User) {
    try {
      logger.authStore.info('Adding Role to New User');
      const tokenManager: TokenManagerModel = (user.toJSON() as { stsTokenManager: TokenManagerModel }).stsTokenManager;
      const res = await fetch('/api/Oauth2/role', {
        headers: setAuthHeaders(tokenManager.accessToken),
        method: 'get',
      });
      const userInfo: auth.UserRecord = await res.json();
      const hasRole = userInfo.customClaims && userInfo.customClaims.role;

      return { role: hasRole ? (userInfo.customClaims.role as string) : '' };
    } catch (error) {
      logger.authStore.error(error);

      throw error;
    }
  }
}

export const authService = new AuthService();

export default AuthService;
