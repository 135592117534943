import { VueMetaPlugin } from 'vue-meta';

export enum UserRoles {
  Admin = 'admin',
  Anonymous = 'anonymous',
  Member = 'member',
  User = 'user',
}

export interface EmailModal {
  email: string;
}

export enum CollectionIds {
  assets = 'assets',
  galleries = 'galleries',
  pages = 'pages',
  photos = 'photos',
}

export interface Context {
  meta?: VueMetaPlugin;
  url?: string;
  [key: string]: unknown;
}
