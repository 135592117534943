import type { HTMLObject } from '@homestyle/shared-data';
import { EditorEvents } from '@homestyle/shared-data';
import type { CreateElement } from 'vue';

import { EventBusService } from '../../../services/event-bus.service';
import { AppEvents } from '../../../utils/app-event.enum';

export default {
  mounted: function () {
    this.$el.addEventListener(
      EditorEvents.save,
      (evt: CustomEvent<HTMLObject[]>) => {
        EventBusService.emit({ event: AppEvents.save, payload: evt.detail });
      }
    );
  },
  props: ['assets', 'content'],
  render(createElement: CreateElement) {
    return createElement('div', [
      createElement(
        'v-btn',
        {
          attrs: { color: 'primary', icon: true },
          on: {
            click: () => EventBusService.emit({ event: AppEvents.assets }),
          },
        },
        [createElement('v-icon', 'autorenew')]
      ),
      createElement('homestyle-editor', {
        attrs: { 'editor-content': this.content, 'gallery-lib': this.assets },
      }),
    ]);
  },
};
