export const firebaseConfig = {
  apiKey: 'AIzaSyDT7SbMxkJgxlpsStInyUAtjzqdRRwNLGA',
  authDomain: 'homestyle-custom.firebaseapp.com',
  databaseURL: 'https://homestyle-custom.firebaseio.com',
  projectId: 'homestyle-custom',
  storageBucket: 'homestyle-custom.appspot.com',
  messagingSenderId: '1083088490171',
  appId: '1:1083088490171:web:2cc9cfde7599058a87239e',
  measurementId: 'G-KF62V2396F',
  clientId:
    '1083088490171-2geuvjvpas4micms3g992ia151m514a6.apps.googleusercontent.com',
  scopes: ['https://www.googleapis.com/auth/photoslibrary.sharing'],
  discoveryDocs: [
    'https://content.googleapis.com/discovery/v1/apis/photoslibrary/v1/rest',
  ],
};
