
































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class LogoComponent extends Vue {}

export default LogoComponent;
