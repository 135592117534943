






















import { mdiLogin, mdiLogout } from '@mdi/js';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { EventBusService } from '../../services/event-bus.service';
import { AuthEvents } from '../../utils/auth-event.enum';

@Component({})
export class AuthButton extends Vue {
	@Prop({ default: false }) isLogged: boolean;

	public get icon() {
		return this.isLogged ? mdiLogout : mdiLogin;
	}

	public clickEvent() {
		EventBusService.emit({ event: AuthEvents.action });
	}
}

export default AuthButton;
