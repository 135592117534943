import type { TokenManagerModel } from '@homestyle/shared-data';
import { UserRoles } from '@homestyle/shared-data';
import firebase from 'firebase/app';
import 'firebase/auth';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { createVuexStore, Getter, Module, Mutation, State } from 'vuex-simple';

import { photosService } from './shared/services/photos.service';
import RootStore from './shared/store';
import { GalleryStore } from './Gallery';
import { PageStore } from './Page';
import { logger } from './shared/utils/logger';

Vue.use(Vuex);

export class MyStore extends RootStore {
  @Module()
  public gallery = new GalleryStore(this);

  @Module()
  public page = new PageStore(this);

  @State()
  protected token = '';

  @Getter()
  public get getToken() {
    return this.token;
  }

  @Mutation()
  public commitToken(payload: string) {
    this.token = payload;
  }

  public init() {
    firebase.auth().onIdTokenChanged((user) => {
      if (!process.env.VUE_APP_SSR) {
        if (user) {
          const userData: TokenManagerModel = user.toJSON()['stsTokenManager'];
          this.commitToken(userData.accessToken);
          if (window && photosService.gapi) {
            photosService.start();
          }
          user
            .getIdTokenResult()
            .then((token) => {
              const userRole = token.claims['role'] || UserRoles.User;
              this.commitCurrentUser({ role: userRole });
            })
            .catch((err) =>
              logger.storeLog.error('Error Getting user Claims', err)
            );
        } else {
          logger.storeLog.info('Logged Out, No User.');
          this.commitToken('');
          if (window && photosService.gapi) {
            photosService.stop();
          }
        }
      }
    });
  }
}

export const myStore = new MyStore();

export const store: Store<MyStore> = createVuexStore(myStore, {
  strict: true,
  modules: {},
  plugins: [],
});

export default store;
