






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export class FooterComponent extends Vue {}

export default FooterComponent;
