import { useEffect, useRef } from 'react';
import { fromEvent } from 'rxjs';

import { storeService } from '../store';

export const useOutsideDismiss = (outDiv: HTMLDivElement) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalEl = modalRef.current;
    const sub = fromEvent(outDiv, 'click').subscribe(evt => {
      const target = evt.target as HTMLElement;

      if (!modalEl.contains(target)) {
        document.body.removeChild(outDiv);
        storeService.setResize(false);
      }
    });

    return () => sub.unsubscribe();
  }, [outDiv]);

  return modalRef;
};
