import interact from 'interactjs';
import { BehaviorSubject } from 'rxjs';

const roundHeight = (ratio: number, width: number) => {
  return Math.round(width * ratio);
};

export const resizeSubject = new BehaviorSubject({ height: 0, width: 0 });

export const setResize = (img: HTMLImageElement) => {
  const ratio = img.height / img.width;

  interact(img)
    .resizable({
      edges: { left: true, right: true, bottom: true, top: true },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictSize({
          max: {
            height: img.naturalHeight,
            width: img.naturalWidth,
          },
        }),
      ],
      preserveAspectRatio: true,
    })
    .on('resizemove', (evt: Interact.InteractEvent) => {
      img.height = roundHeight(ratio, evt.rect.width);
      img.width = evt.rect.width;
    })
    .on('resizeend', (evt: Interact.InteractEvent) =>
      resizeSubject.next({ height: roundHeight(ratio, evt.rect.width), width: evt.rect.width }),
    );
};
