import { Logger, LogLevel } from '@firebase/logger';
// import { analytics } from 'firebase/app';
// import 'firebase/analytics';

export enum LoggerNamespaces {
  admin = 'admin',
  auth = 'auth',
  store = 'store',
}

export class MyLogger {
  public adminStore = new Logger('admin');
  public authStore = new Logger('auth');
  public eventError = new Logger('event');
  public storeLog = new Logger('store');

  public setup(isDev = false) {
    const level = isDev ? LogLevel.VERBOSE : LogLevel.ERROR;
    this.setLevels(level);
  }

  private setLevels(level: LogLevel) {
    this.adminStore.logLevel = level;
    this.authStore.logLevel = level;
    this.storeLog.logLevel = level;
  }
}

export const logger = new MyLogger();

export default logger;
