















import { EventBusService } from '../../services/event-bus.service';
import type { EventBus } from '../../services/event-bus.service';
import { AppEvents, AppEventLimit } from '../../utils/app-event.enum';
import Vue from 'vue';

export const ProgressBar = Vue.extend({
	data: function () {
		return {
			isActive: false,
		};
	},
	methods: {
		setDone: function () {
			this.isActive = false;
		},
		setLoading: function () {
			this.isActive = true;
		},
	},
	mounted: function () {
		EventBusService.on(AppEvents.complete, this.setDone);
		EventBusService.on(AppEvents.sync, this.setLoading);
		EventBusService.on(AppEvents.assets, this.setLoading);
		EventBusService.on(AppEvents.save, this.setLoading);
	},
});

Vue.component('progress-bar', ProgressBar);

export default ProgressBar;
